<template>
  <div v-if="address">
    <div v-if="raw">
      <div>{{address.street}} {{address.streetNumber}}</div>
      <div>{{address.country}} {{address.zipcode}} {{address.placeOfResidence}}</div>
    </div>
    <div v-else>
      <p><span>{{address.street}} {{address.streetNumber}}</span></p>
      <p><span>{{address.zipcode}} {{address.placeOfResidence}}</span></p>
      <p><span>{{labels.country[address.country]}}</span></p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AddressView',

    props: {
      address: {
        required: true
      },
      raw: {
        required: false
      }
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },
    },
  }
</script>

<style lang="scss" scoped>
  p {
    margin: 0;
  }

  span {
    color: cornflowerblue;
  }
</style>