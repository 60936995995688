<template>
  <div v-if="isAuthenticated && isSage">
    <div class="grid-x grid-padding-x">
      <div class="cell callout success">
        <h3>
          <md-icon style="color: black">supervisor_account</md-icon>&nbsp;&nbsp;&nbsp;Kunden
        </h3>
      </div>
    </div>
    <div class="grid-x grid-padding-x grid-padding-y">
      <div class="cell large-6">
        <md-table id="customers-table-id" v-model="searchedCustomers" md-sort="customerNo" md-sort-order="asc"
                  md-card md-fixed-header @md-selected="onSelectCustomer">
          <md-table-toolbar>
            <div class="md-toolbar-section-start grid-x grid-padding-x">
              <div class="cell large-5 medium-5 small-6">
                <p class="md-title ai-number">
                  {{ searchedCustomers.length }} Kunde<span v-if="searchedCustomers.length !== 1">n</span>
                </p>
              </div>
              <div class="cell large-1 medium-1 hide-for-small-only">
                <vue-simple-spinner v-if="sending"></vue-simple-spinner>
              </div>
              <div class="cell large-6 medium-6 small-6">
                <md-field md-clearable class="md-toolbar-section-end">
                  <md-input placeholder="Suche Kunde" v-model="search"
                            @input="searchOnCustomersTable"/>
                </md-field>
              </div>
            </div>
          </md-table-toolbar>

          <md-table-empty-state v-if="search"
                                md-label="Keinen Kunden gefunden"
                                :md-description="`Keinen Kunden mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
          </md-table-empty-state>

          <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)"
                        md-selectable="single">
            <md-table-cell style="max-width: 200px;" md-label="Kunden-Nr" md-sort-by="customerNo">
              {{ item.customerNo }}
            </md-table-cell>
            <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <div class="cell large-6">
        <md-card  id="customers-view-card-id">
          <md-card-header style="height: 100px;">
            <div v-if="selectedCustomer" >
              <div class="md-title">{{ selectedCustomer.name }}</div>
              <div class="md-subhead">{{ selectedCustomer.customerNo }}</div>
              <br>
              <div style="height: 8px;">
                <md-progress-bar md-mode="indeterminate" v-if="sending"/>
              </div>
            </div>
            <div v-else>
              <h5><i>Bitte wählen Sie einen Kunden aus</i></h5>
            </div>
          </md-card-header>
          <md-card-content style="height: 740px">
            <md-tabs v-if="selectedCustomer" >
              <md-tab id="overview-tab-id" md-label="Übersicht" style="margin-top: 1rem">
                <OrganizationView :organization="selectedCustomer"/>
              </md-tab>
              <md-tab id="contract-tab-id" md-label="Vertrag" class="md-scrollbar" style="margin-top: 1rem">
                <ContractView v-if="selectedCustomer.ai42Contract"
                              :card-classes="'no-box-shadow'"
                              :layout-classes="'cell'"
                              :organization="selectedCustomer"
                              :contract="selectedCustomer.ai42Contract"/>
              </md-tab>
              <md-tab id="accounting-tab-id" md-label="Rechnungen" style="margin-top: 1rem">
                <SettlementsView :contract="selectedCustomer.ai42Contract" :layout-classes="'cell'"/>
              </md-tab>
            </md-tabs>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner';
import HttpErrorHandler from "../services/HttpErrorHandler";
import SageService from "../services/SageService";
import OrganizationService from "../services/OrganizationService";
import ContractView from "../components/views/account/ContractView";
import SettlementsView from "../components/views/account/SettlementsView";
import OrganizationView from "../components/views/OrganizationView";
import jQuery from "jquery";

const toLower = text => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)));
  }

  return items;
};

export default {
  name: 'Settlements',

  components: {
    'vue-simple-spinner': Spinner,
    ContractView,
    SettlementsView,
    OrganizationView,
  },

  created() {
  },

  beforeDestroy() {
  },

  mounted() {
    this.reloadAll();
  },

  data() {
    return {
      sending: false,
      success: false,

      customers: [],
      searchedCustomers: [],
      search: '',
      organizations: [],

      selectedCustomer: null,
    }
  },

  methods: {

    getClass: ({id, active}) => ({
      'md-primary': id === id,
      'inactive-child': active === false,
    }),

    reloadAll() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable) {
          this.getCustomers();
          this.getOrganizations();
          clearInterval(reloadIntervalId);

        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Einrichtung / Organisation');
          }
        }
      }, 250);
    },

    getCustomers() {
      this.sending = true;

      SageService.getCustomers()
          .then(response => {
            this.customers = response.data;
            this.sending = false;
            this.searchOnCustomersTable();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Liste aller Kunden');
            this.sending = false;
          });
    },

    getOrganizations() {
      this.sending = true;
      OrganizationService.getAllOrganizations()
          .then(response => {
            this.organizations = response.data;
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Liste aller Organisationen');
            this.sending = false;
          });
    },

    searchOnCustomersTable() {
      this.searchedCustomers =searchByName(this.customers, this.search);
      setTimeout(() => jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'min-height: 702px'), 150);
    },

    onSelectCustomer(item) {
      this.selectedCustomer = item;
      if (this.selectedCustomer) {
        if (!this.selectedCustomer.ai42Contract) {
          this.getCustomerContract(this.selectedCustomer);
        }
      }
    },

    getCustomerContract(customer) {
      this.sending = true;

      SageService.getCustomerContract(customer.customerNo)
          .then(response => {
            this.sending = false;
            customer.ai42Contract = response.data;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden des Vertrags für Kunde <b>' + customer.name + '</b>');
            this.sending = false;
          });
    },
  },

  computed: {
    dataAvailable() {
      return this.isAuthenticated && this.isSage;
    },

    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    labels() {
      return this.$store.getters.labels;
    },

    isSage() {
      return this.$store.getters.sage;
    },
  }
}
</script>

<style lang="scss" scoped>
h5 {
  color: lightseagreen;
}

.md-tab {
  padding-left: 8px;
  max-height: 660px;
  overflow: auto;
}

.hidden {
  display: none;
}
</style>

<style>
.no-box-shadow {
  box-shadow: none;
}
</style>